import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=3d2ae2ab&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=3d2ae2ab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d2ae2ab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderLogo: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Header/Logo.vue').default,HeaderNavigation: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Header/Navigation.vue').default,HeaderSearchBar: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Header/SearchBar.vue').default,HeaderMobileHelp: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Header/MobileHelp.vue').default,HeaderLangSwitch: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Header/LangSwitch.vue').default,Auth: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Auth.vue').default,ElementsCartControl: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/elements/CartControl.vue').default})
