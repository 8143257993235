import { render, staticRenderFns } from "./Compact.vue?vue&type=template&id=644fbb1a&scoped=true&"
import script from "./Compact.vue?vue&type=script&lang=js&"
export * from "./Compact.vue?vue&type=script&lang=js&"
import style0 from "./Compact.vue?vue&type=style&index=0&id=644fbb1a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644fbb1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventCardImage: require('/home/ticketshoplv/public_html/naviband.tour.tickets/components/Event/CardImage.vue').default})
